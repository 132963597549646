<template>
  <div class="promo-view" v-if="post" v-visible="fireViewEvent">
    <default-space>
      <div class="d-md-flex">
        <div class="promo-carousel-container" ref="promo_carousel_container" :style="carouselHeight">
          <div class="promo-image-carousel">
            <promo-image-carousel
                :images="images"
                :height="carouselHeight"
                :width="carouselWidth"
            />
          </div>
        </div>
        <div class="promo-content-container">
          <div class="d-flex justify-content-between">
            <Voting :post="post" @like="makeHit" />

            <div class="d-flex align-items-center">
              <div v-if="!xsOnly && post.is_expired" class="expired-promo me-2">{{ $t('post.expired.expired') }}</div>
              <div v-else-if="!xsOnly" class="expiration-date me-2">{{
                  post.valid_to_formatted ? $t('promo.expires_at', {date: post.valid_to_formatted}) : ''
                }}
              </div>
              <share  class="me-2" :with-text="!xsOnly" @share="processShare"></share>
              <div class="favorite-wrapper">
                <i
                    :class="{'active icon-heart-2': post.is_favourite, 'icon-heart': !post.is_favourite}"
                    @click="processFavourite"
                />
              </div>
            </div>
          </div>

          <div class="promo-title">
            {{ title }}
          </div>

          <div class="d-inline-flex align-items-center " >
            <div class="price" v-if="post.old_price || post.new_price || post.discount">
              <template v-if="post.old_price">
                <span class="price--old">
                  € {{ post.old_price.toFixed(2) }}
                </span>
                <span class="price--separator"> | </span>
              </template>

              <span v-if="post.new_price" class="price--new">
                € {{ post.new_price }}
              </span>
              <span v-else-if="post.discount" class="price--discount">
                {{ $t('post.discount') }}: {{ post.discount }}
              </span>
            </div>

           <post-promo-webshop-label  :webshop="post.webshop" :webshops="post.webshops" :stores="post.stores" />
          </div>
          <div class="promo-description">
            <dynamic-content :content="post.content"/>
          </div>

          <template v-if="post.free_shipping">
            <span class="promo-free-shipping">
              {{ shippingNumber }}
            </span>
          </template>

          <div class="promo-tags">
            <hash-tags :tags="tags"/>
          </div>
          <div class="promo-discount">

            <copy-button-group
                v-if="post.type.id===5"
                :promo-id="post.id"
                :discount-code="post.discount_code"
                :discount-codes-count="post.discount_codes_count"
                :discount-url="post.discount_url"
                :event-outgoing-click-meta="post.event_outgoing_click_meta"
               >
            </copy-button-group>
            <go-webshop-button
                v-else-if="webshopUrl !== null"
                :url="webshopUrl"
                :event-outgoing-click-meta="post.event_outgoing_click_meta"
            />
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <router-localized-link :to="{name: 'profile', params: {userId: String(post.user.id)}}">
                <el-avatar
                    :src="post.user.avatar_url"
                    shape="circle"
                    class="promo-author-avatar"
                    loading="lazy"
                />
              </router-localized-link>

              <div class="promo-author">
                <div class="promo-author-top-container">
                  <div class="promo-author-name">
                    <router-localized-link :to="{name: 'profile', params: {userId: String(post.user.id)}}">
                      {{ post.user.full_name }}
                    </router-localized-link>
                  </div>
                </div>
                <div class="promo-valid-time">
                  {{ post.published_text }}
                </div>
              </div>
            </div>
            <report-expired-promo v-if="post.is_reportable" :promo="post" morphable_type="Post"/>
          </div>
        </div>
      </div>
      <reactions-info
          class="px-2"
          :rating="post.rating_calculated"
          :comments-count="commentsCount"
      />

      <div class="promo-footer">
        <div class="leave-comment mt-md-4 mt-3">{{$t('comment.leave_comment')}}</div>
        <div class="horizontal-promo__comments border-top-0 mt-1">

          <comment-form ref="commentForm" @submit="pushComment"/>

          <comments-loading :loading="commentsLoading"/>

          <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"/>

          <comments-loading :loading="commentsPushing"/>

        </div>
      </div>
    </default-space>
  </div>
</template>

<script>
import post from '@/plugins/post';
import {actions} from '@/mixins/post/actions';
import {comments} from '@/mixins/comments';
import {slug} from '@/mixins/slug';
import {promo_carousel_container} from '@/mixins/promo/promo_carousel_container';
import {needAuth} from '@/mixins/auth/needAuth';
import {discountCodes} from '@/mixins/post/discountCodes';
import {mapActions, mapGetters} from 'vuex';
import store from '@/store';
import HashTags from '@/components/feed/post/HashTags';
import CommentsLoading from '@/components/feed/comment/CommentsLoading';
import Comments from '@/components/feed/comment/Comments';
import CommentForm from '@/components/feed/comment/CommentForm';
import DefaultSpace from '@/views/DefaultSpace';
import DynamicContent from '@/components/feed/post/DynamicContent';
import GoWebshopButton from '@/components/feed/buttons/GoWebshopButton';
import PromoImageCarousel from '@/components/feed/promo/PromoImageCarousel';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import Voting from '@/components/feed/promo/Voting.vue';
import Share from '@/components/feed/Share.vue';
import ReportExpiredPromo from '@/components/feed/post/ReportExpiredPromo.vue'
import ReactionsInfo from '@/components/feed/promo/ReactionsInfo.vue';
import CopyButtonGroup from '@/components/feed/buttons/CopyButtonGroup.vue';
export default {
  name: 'PostView',
  components: {
    CopyButtonGroup,
    ReactionsInfo,
    Share,
    Voting,
    PromoImageCarousel,
    GoWebshopButton,
    DynamicContent,
    DefaultSpace,
    CommentForm,
    Comments,
    CommentsLoading,
    HashTags,
    RouterLocalizedLink,
    ReportExpiredPromo
  },
  mixins: [actions, comments, slug, needAuth, discountCodes, promo_carousel_container],
  data() {
    return {
      vuexModule: 'promo',
      discountCodeCopied: false,
    }
  },
  computed: {
    ...mapGetters('promo', [
      'CURRENT_PROMO',
    ]),
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.post,
        morphable_type: 'Post'
      }
    },
    post() {
      return this.CURRENT_PROMO;
    },
    tags() {
      const result = [];
      if (_.get(this.post, 'type.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'type.translation.alias')),
          link: this.$router.resolve({name: 'home'}).href,
          entityType: 'type'
        });
      }

      if (_.get(this.post, 'webshops[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'webshops[0].translation.title')),
          link: this.$router.resolve({
            name: 'webshop.show',
            params: {webshopSlug: _.get(this.post, 'webshops[0].translation.route')}
          }).href,
          entityType: 'webshop'
        });
      }

      if (_.get(this.post, 'stores[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'stores[0].translation.title')),
          link: this.$router.resolve({
            name: 'store.show',
            params: {storeSlug: _.get(this.post, 'stores[0].translation.route')}
          }).href,
          entityType: 'store'
        });
      }

      if (_.get(this.post, 'brands[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'brands[0].translation.title')),
          link: this.$router.resolve({
            name: 'brand.show',
            params: {brandSlug: _.get(this.post, 'brands[0].translation.route')}
          }).href,
          entityType: 'brand'
        });
      }

      if (_.get(this.post, 'products[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'products[0].translation.title')),
          link: this.$router.resolve({
            name: 'product.show',
            params: {productSlug: _.get(this.post, 'products[0].translation.route')}
          }).href,
          entityType: 'product'
        });
      }

      return result;
    },
    images() {
      if (this.post.images.length) {
        return this.post.images;
      }
      if (this.post.webshop) {
        return [{src: this.post.webshop.image_url}];
      }
      return [{src: require('@/assets/images/promo_no_image.svg')}];
    },
    title() {
      return post.getTitle(this.post);
    },
    webshopUrl() {
      if (this.post.url !== null) {
        return this.post.url;
      }
      return _.get(this.post, 'webshops[0].translation.affiliate_url', null);
    },
    shippingNumber() {
      if (this.post.shipping_number === null){
        return this.$t('post.free_shipping');
      }
      return this.$t('post.shipping_number') + ' € ' + this.post.shipping_number;
    },

  },
  mounted() {
    this.commentsCount = this.post.comments_count;
    this.comments = this.post.comments;
    this.visibleComments = _.min([3, this.post.comments.length]);

    if (localStorage.getItem('CopiedDiscountCodeId')) {
        this.loadDiscountCode(localStorage.getItem('CopiedDiscountCodeId'));
      localStorage.removeItem('CopiedDiscountCodeId');
    }
  },
  methods: {
    ...mapActions('promo', [
      'FETCH_CURRENT_PROMO',
      'RESET_PROMOS',
    ]),
    openWebshop() {
      window.open(this.onlinePromo.url, '_blank');
    },
    showMoreComments() {
      this.showMoreCommentsProcess('Post', this.post.id);
    },
    pushComment(form) {
      this.pushCommentProcess('Post', this.post.id, form);
    },
    processShare(destination) {
      const link = new URL(
          this.$router.resolve({
            name: 'posts.show',
            params: {id: this.post.id}
          }).href,
          window.location.origin
      ).href;
      this.shareLink(link, destination);
    },
    loadDiscountCode(id) {
      axios.get('/feed-items?post=' + id).then((response) => {
        this.discountCode = _.get(response, 'data.data[0].data', null);
        if (this.discountCode) {
          this.discountCodeCopied = true;
        }
      });
    },
    /**
     * Like
     */
    makeHit() {
      const isHit = this.post.is_hit;
      const thisElem = this

      this.processHit()?.then(() => {
        thisElem.$emit('voted')
      })
    },
    fireViewEvent() {
      this.processEventView(this.preparePostViewEventData(this.post));
    }
  }
}
</script>
